import React from "react"
import { Link } from "gatsby"
import NavBar from "../components/NavBar"
import Footer from "../components/Footer"
import Metatags from "../components/metatags"
import UseSiteMetadata from "../components/UseSiteMetadata"

const InfoWeb = ({ pageContext, location }) => {
  const { siteUrl } = UseSiteMetadata()
  const fullUrl = `${siteUrl}${location.pathname}`
  const schemaData = {
    "@context": "https://schema.org",
    "@graph": [
      {
        "@context": "https://schema.org",
        "@type": "Organization",
        email: "info@netbistrot.com",
        name: "NetBistrot",
        logo: "https://netbistrot.com/netbistrot-icon-540.png",
      },
      {
        "@type": "WebSite",
        "@id": `https://netbistrot.com${location.pathname}`,
        url: `https://netbistrotcom${location.pathname}`,
        name: "NetBistrot",
        inLanguage: "es-ES",

      },
    ],
  }
  return (
    <>
      <Metatags
        title="NetBistrot - Digital agency | websites informativos"
        description="El website informativo está dirigido a pequeñas empresas que quieren asegurar su presencia en internet."
        url={fullUrl}
        image="https://netbistrot.com/netbistrot-icon-540.png"
        keywords="web design, web studio, serverless, user experience"
        language={pageContext.langKey}
        schemaData={schemaData}
      />
      <NavBar />
      <div className="h-10" />
      <div className="container mx-auto">
        <div className=" mx-2 md:mx-0">
          <div className="grid grid-cols-1 md:grid-cols-12 gap-4">
            <div className="col-span-2" />
            <div className="col-span-10">
              <h1>websites informativos</h1>
            </div>
          </div>
          <div className="h-8" />
          <div className="grid grid-cols-1 md:grid-cols-12 gap-4">
            <div className="col-span-4" />
            <div className="col-span-6">
              <span className="text-lg">
                El website informativo está dirigido a pequeñas empresas que
                quieren asegurar su presencia en internet.
                <br />
                <br />
                Tiene un pequeño número de páginas con las características
                principales, como los principales productos o servicios, logros
                y metas, enlaces a sus redes sociales y sus contactos.
                <br />
                <br />
                Como este tipo de website no tiene actualizaciones frecuentes,
                sus contenidos no están en una base de datos.
                <br />
                <br />
                Esto asegura una gran libertad en el diseño de cada página con
                diferentes plantillas y contenidos.
                <br />
                <br />
                Ve un ejemplo:
                <br />
                <br />
              </span>
              <div>
                <a
                  className="btn-icon rounded text-lg text-white bg-blue-600 hover:bg-blue-800 focus:outline-none focus:ring px-3 py-1.5"
                  href="https://daucodesigns.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  daucodesigns.com
                </a>
              </div>
              <div className="h-8" />
              <div className="text-center">
                <span className="text-2xl">
                  Vamos a trabajar juntos?
                  <br />
                  <br />
                </span>
                <Link
                  className="btn-icon rounded text-lg text-white bg-blue-600 hover:bg-blue-800 focus:outline-none focus:ring px-4 py-2 font-semibold"
                  to="/es/lets-work-together/?service=informative-website"
                >
                  hagamos un website informativo
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="h-10" />
      <Footer lang="en" />
    </>
  )
}

export default InfoWeb
